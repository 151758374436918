<template>
  <el-main>
    <el-form style="width: 700px" label-width="100px">
      <el-form-item label="提现方式：">
        <el-radio-group v-model="type">
          <el-radio :label="1">提现到银行卡</el-radio>
          <el-radio :label="2">提现到微信钱包</el-radio>
        </el-radio-group>
        <p>提现到微信钱包，仅支持微信小程序。其他应用，会默认银行卡。</p>
      </el-form-item>
      <el-form-item label="提现额度：">
        <span>提现到微信钱包，需要微信商户号开通企业打款到零钱功能</span>
        <el-input v-model="withdraw_amount"></el-input>
        <span>当到达此额度时才可提现，不填则表示随时可以提现</span>
      </el-form-item>
    </el-form>
    <Preservation @preservation="saveSet"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  data() {
    return {
      type: 1,
      withdraw_amount: '',
    };
  },
  created() {
    this.getWithdrawSet();
  },
  methods: {
    getWithdrawSet() {
      this.$axios.post(this.$api.distribution.withdrawInfo).then(res => {
        if (res.code == 0) {
          if (res.result) {
            this.type = res.result.type;
            this.withdraw_amount = res.result.withdraw_amount;
          }
        }
      });
    },
    saveSet() {
      this.$axios
        .post(this.$api.distribution.withdrawEdit, {
          type: this.type,
          withdraw_amount: this.withdraw_amount,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
          } else if (res.code == -2) {
            this.$confirm(res.msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }).then(() => {
              this.$router.push('/set/Cashier');
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  height: 100%;
}
</style>
